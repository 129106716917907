import {createConfig} from '@formkit/core';
import {getIcon, loadIcon} from '@iconify/vue';
import {iconToSVG} from '@iconify/utils';
import theme from './formkit.theme';
import {createInput} from '@formkit/vue';
import UploadFile from '~/components/formkit/UploadFile.vue';
import Rank from '~/components/formkit/Rank.vue';

export default createConfig({
  icons: {
    checkboxDecorator: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path fill="currentColor" fill-rule="evenodd" d="M16.704 4.153a.75.75 0 0 1 .143 1.052l-8 10.5a.75.75 0 0 1-1.127.075l-4.5-4.5a.75.75 0 0 1 1.06-1.06l3.894 3.893l7.48-9.817a.75.75 0 0 1 1.05-.143Z" clip-rule="evenodd"/></svg>',
    radioDecorator: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="currentColor" d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10s10-4.47 10-10S17.53 2 12 2z"/></svg>',
  },
  async iconLoader(iconName: string) {
    iconName = {
      selectIcon: 'heroicons:selector',
    }[iconName] ?? iconName;

    let icon = getIcon(iconName);

    if (!icon) {
      await loadIcon(iconName);

      icon = getIcon(iconName);
    }

    if (icon) {
      const {body, attributes: {height, viewBox, width}} = iconToSVG(icon);
      return `<svg viewBox="${viewBox}" height="${height}" width="${width}">${body}</svg>`;
    }

    return null;
  },
  config: {
    classes: theme,
  },
  inputs: {
    uploadFile: createInput(UploadFile, {
      family: 'file',
    }),
    rank: createInput(Rank, {
      props: ['options'],
    }),
  },
});
