import { default as _91_46_46_46page_93Rq8BGHD7vvMeta } from "/data/www/batobs.org/vleermuisvang-db/nuxt/pages/[...page].vue?macro=true";
import { default as change_45password3vHPkSwMQkMeta } from "/data/www/batobs.org/vleermuisvang-db/nuxt/pages/account/change-password.vue?macro=true";
import { default as index6kzrpA3n9WMeta } from "/data/www/batobs.org/vleermuisvang-db/nuxt/pages/account/index.vue?macro=true";
import { default as _91token_93fGPo9ZKD6aMeta } from "/data/www/batobs.org/vleermuisvang-db/nuxt/pages/auth/activate/[token].vue?macro=true";
import { default as loginl4Cghsj8kDMeta } from "/data/www/batobs.org/vleermuisvang-db/nuxt/pages/auth/login.vue?macro=true";
import { default as registeroLFORRWr0rMeta } from "/data/www/batobs.org/vleermuisvang-db/nuxt/pages/auth/register.vue?macro=true";
import { default as request_45password86r605QjktMeta } from "/data/www/batobs.org/vleermuisvang-db/nuxt/pages/auth/request-password.vue?macro=true";
import { default as _91token_93gjbPzfMuvKMeta } from "/data/www/batobs.org/vleermuisvang-db/nuxt/pages/auth/reset-password/[token].vue?macro=true";
import { default as _91id_93RVB4CvjZIoMeta } from "/data/www/batobs.org/vleermuisvang-db/nuxt/pages/forms/[id].vue?macro=true";
import { default as createziVnueD03wMeta } from "/data/www/batobs.org/vleermuisvang-db/nuxt/pages/forms/create.vue?macro=true";
import { default as index9CCKtbmVo7Meta } from "/data/www/batobs.org/vleermuisvang-db/nuxt/pages/forms/index.vue?macro=true";
import { default as indexgBoPk4aMdQMeta } from "/data/www/batobs.org/vleermuisvang-db/nuxt/pages/index.vue?macro=true";
import { default as index7dGNMBWGRdMeta } from "/data/www/batobs.org/vleermuisvang-db/nuxt/pages/observations/index.vue?macro=true";
import { default as editwYRCk3ME0LMeta } from "/data/www/batobs.org/vleermuisvang-db/nuxt/pages/projects/[uuid]/edit.vue?macro=true";
import { default as index1Ex2zxuoJ2Meta } from "/data/www/batobs.org/vleermuisvang-db/nuxt/pages/projects/[uuid]/index.vue?macro=true";
import { default as index9Nc8X7706wMeta } from "/data/www/batobs.org/vleermuisvang-db/nuxt/pages/projects/[uuid]/locations/index.vue?macro=true";
import { default as completedTOwJBEgIlpMeta } from "/data/www/batobs.org/vleermuisvang-db/nuxt/pages/projects/[uuid]/visits/[visitId]/completed.vue?macro=true";
import { default as countyZ4LL72cjpMeta } from "/data/www/batobs.org/vleermuisvang-db/nuxt/pages/projects/[uuid]/visits/[visitId]/count.vue?macro=true";
import { default as editchYVrdv6oXMeta } from "/data/www/batobs.org/vleermuisvang-db/nuxt/pages/projects/[uuid]/visits/[visitId]/edit.vue?macro=true";
import { default as createhSeXJjv1G0Meta } from "/data/www/batobs.org/vleermuisvang-db/nuxt/pages/projects/[uuid]/visits/create.vue?macro=true";
import { default as create9ZTrX4lQlAMeta } from "/data/www/batobs.org/vleermuisvang-db/nuxt/pages/projects/create.vue?macro=true";
import { default as indexx1xkn1YEt8Meta } from "/data/www/batobs.org/vleermuisvang-db/nuxt/pages/projects/index.vue?macro=true";
import { default as create5edMsShuhEMeta } from "/data/www/batobs.org/vleermuisvang-db/nuxt/pages/visits/create.vue?macro=true";
import { default as indexGfUy1q6slYMeta } from "/data/www/batobs.org/vleermuisvang-db/nuxt/pages/visits/index.vue?macro=true";
export default [
  {
    name: _91_46_46_46page_93Rq8BGHD7vvMeta?.name ?? "page",
    path: _91_46_46_46page_93Rq8BGHD7vvMeta?.path ?? "/:page(.*)*",
    meta: _91_46_46_46page_93Rq8BGHD7vvMeta || {},
    alias: _91_46_46_46page_93Rq8BGHD7vvMeta?.alias || [],
    redirect: _91_46_46_46page_93Rq8BGHD7vvMeta?.redirect || undefined,
    component: () => import("/data/www/batobs.org/vleermuisvang-db/nuxt/pages/[...page].vue").then(m => m.default || m)
  },
  {
    name: change_45password3vHPkSwMQkMeta?.name ?? "account-change-password",
    path: change_45password3vHPkSwMQkMeta?.path ?? "/account/change-password",
    meta: change_45password3vHPkSwMQkMeta || {},
    alias: change_45password3vHPkSwMQkMeta?.alias || [],
    redirect: change_45password3vHPkSwMQkMeta?.redirect || undefined,
    component: () => import("/data/www/batobs.org/vleermuisvang-db/nuxt/pages/account/change-password.vue").then(m => m.default || m)
  },
  {
    name: index6kzrpA3n9WMeta?.name ?? "account",
    path: index6kzrpA3n9WMeta?.path ?? "/account",
    meta: index6kzrpA3n9WMeta || {},
    alias: index6kzrpA3n9WMeta?.alias || [],
    redirect: index6kzrpA3n9WMeta?.redirect || undefined,
    component: () => import("/data/www/batobs.org/vleermuisvang-db/nuxt/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: _91token_93fGPo9ZKD6aMeta?.name ?? "auth-activate-token",
    path: _91token_93fGPo9ZKD6aMeta?.path ?? "/auth/activate/:token()",
    meta: _91token_93fGPo9ZKD6aMeta || {},
    alias: _91token_93fGPo9ZKD6aMeta?.alias || [],
    redirect: _91token_93fGPo9ZKD6aMeta?.redirect || undefined,
    component: () => import("/data/www/batobs.org/vleermuisvang-db/nuxt/pages/auth/activate/[token].vue").then(m => m.default || m)
  },
  {
    name: loginl4Cghsj8kDMeta?.name ?? "auth-login",
    path: loginl4Cghsj8kDMeta?.path ?? "/auth/login",
    meta: loginl4Cghsj8kDMeta || {},
    alias: loginl4Cghsj8kDMeta?.alias || [],
    redirect: loginl4Cghsj8kDMeta?.redirect || undefined,
    component: () => import("/data/www/batobs.org/vleermuisvang-db/nuxt/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: registeroLFORRWr0rMeta?.name ?? "auth-register",
    path: registeroLFORRWr0rMeta?.path ?? "/auth/register",
    meta: registeroLFORRWr0rMeta || {},
    alias: registeroLFORRWr0rMeta?.alias || [],
    redirect: registeroLFORRWr0rMeta?.redirect || undefined,
    component: () => import("/data/www/batobs.org/vleermuisvang-db/nuxt/pages/auth/register.vue").then(m => m.default || m)
  },
  {
    name: request_45password86r605QjktMeta?.name ?? "auth-request-password",
    path: request_45password86r605QjktMeta?.path ?? "/auth/request-password",
    meta: request_45password86r605QjktMeta || {},
    alias: request_45password86r605QjktMeta?.alias || [],
    redirect: request_45password86r605QjktMeta?.redirect || undefined,
    component: () => import("/data/www/batobs.org/vleermuisvang-db/nuxt/pages/auth/request-password.vue").then(m => m.default || m)
  },
  {
    name: _91token_93gjbPzfMuvKMeta?.name ?? "auth-reset-password-token",
    path: _91token_93gjbPzfMuvKMeta?.path ?? "/auth/reset-password/:token()",
    meta: _91token_93gjbPzfMuvKMeta || {},
    alias: _91token_93gjbPzfMuvKMeta?.alias || [],
    redirect: _91token_93gjbPzfMuvKMeta?.redirect || undefined,
    component: () => import("/data/www/batobs.org/vleermuisvang-db/nuxt/pages/auth/reset-password/[token].vue").then(m => m.default || m)
  },
  {
    name: _91id_93RVB4CvjZIoMeta?.name ?? "forms-id",
    path: _91id_93RVB4CvjZIoMeta?.path ?? "/forms/:id()",
    meta: _91id_93RVB4CvjZIoMeta || {},
    alias: _91id_93RVB4CvjZIoMeta?.alias || [],
    redirect: _91id_93RVB4CvjZIoMeta?.redirect || undefined,
    component: () => import("/data/www/batobs.org/vleermuisvang-db/nuxt/pages/forms/[id].vue").then(m => m.default || m)
  },
  {
    name: createziVnueD03wMeta?.name ?? "forms-create",
    path: createziVnueD03wMeta?.path ?? "/forms/create",
    meta: createziVnueD03wMeta || {},
    alias: createziVnueD03wMeta?.alias || [],
    redirect: createziVnueD03wMeta?.redirect || undefined,
    component: () => import("/data/www/batobs.org/vleermuisvang-db/nuxt/pages/forms/create.vue").then(m => m.default || m)
  },
  {
    name: index9CCKtbmVo7Meta?.name ?? "forms",
    path: index9CCKtbmVo7Meta?.path ?? "/forms",
    meta: index9CCKtbmVo7Meta || {},
    alias: index9CCKtbmVo7Meta?.alias || [],
    redirect: index9CCKtbmVo7Meta?.redirect || undefined,
    component: () => import("/data/www/batobs.org/vleermuisvang-db/nuxt/pages/forms/index.vue").then(m => m.default || m)
  },
  {
    name: indexgBoPk4aMdQMeta?.name ?? "index",
    path: indexgBoPk4aMdQMeta?.path ?? "/",
    meta: indexgBoPk4aMdQMeta || {},
    alias: indexgBoPk4aMdQMeta?.alias || [],
    redirect: indexgBoPk4aMdQMeta?.redirect || undefined,
    component: () => import("/data/www/batobs.org/vleermuisvang-db/nuxt/pages/index.vue").then(m => m.default || m)
  },
  {
    name: index7dGNMBWGRdMeta?.name ?? "observations",
    path: index7dGNMBWGRdMeta?.path ?? "/observations",
    meta: index7dGNMBWGRdMeta || {},
    alias: index7dGNMBWGRdMeta?.alias || [],
    redirect: index7dGNMBWGRdMeta?.redirect || undefined,
    component: () => import("/data/www/batobs.org/vleermuisvang-db/nuxt/pages/observations/index.vue").then(m => m.default || m)
  },
  {
    name: editwYRCk3ME0LMeta?.name ?? "projects-uuid-edit",
    path: editwYRCk3ME0LMeta?.path ?? "/projects/:uuid()/edit",
    meta: editwYRCk3ME0LMeta || {},
    alias: editwYRCk3ME0LMeta?.alias || [],
    redirect: editwYRCk3ME0LMeta?.redirect || undefined,
    component: () => import("/data/www/batobs.org/vleermuisvang-db/nuxt/pages/projects/[uuid]/edit.vue").then(m => m.default || m)
  },
  {
    name: index1Ex2zxuoJ2Meta?.name ?? "projects-uuid",
    path: index1Ex2zxuoJ2Meta?.path ?? "/projects/:uuid()",
    meta: index1Ex2zxuoJ2Meta || {},
    alias: index1Ex2zxuoJ2Meta?.alias || [],
    redirect: index1Ex2zxuoJ2Meta?.redirect || undefined,
    component: () => import("/data/www/batobs.org/vleermuisvang-db/nuxt/pages/projects/[uuid]/index.vue").then(m => m.default || m)
  },
  {
    name: index9Nc8X7706wMeta?.name ?? "projects-uuid-locations",
    path: index9Nc8X7706wMeta?.path ?? "/projects/:uuid()/locations",
    meta: index9Nc8X7706wMeta || {},
    alias: index9Nc8X7706wMeta?.alias || [],
    redirect: index9Nc8X7706wMeta?.redirect || undefined,
    component: () => import("/data/www/batobs.org/vleermuisvang-db/nuxt/pages/projects/[uuid]/locations/index.vue").then(m => m.default || m)
  },
  {
    name: completedTOwJBEgIlpMeta?.name ?? "projects-uuid-visits-visitId-completed",
    path: completedTOwJBEgIlpMeta?.path ?? "/projects/:uuid()/visits/:visitId()/completed",
    meta: completedTOwJBEgIlpMeta || {},
    alias: completedTOwJBEgIlpMeta?.alias || [],
    redirect: completedTOwJBEgIlpMeta?.redirect || undefined,
    component: () => import("/data/www/batobs.org/vleermuisvang-db/nuxt/pages/projects/[uuid]/visits/[visitId]/completed.vue").then(m => m.default || m)
  },
  {
    name: countyZ4LL72cjpMeta?.name ?? "projects-uuid-visits-visitId-count",
    path: countyZ4LL72cjpMeta?.path ?? "/projects/:uuid()/visits/:visitId()/count",
    meta: countyZ4LL72cjpMeta || {},
    alias: countyZ4LL72cjpMeta?.alias || [],
    redirect: countyZ4LL72cjpMeta?.redirect || undefined,
    component: () => import("/data/www/batobs.org/vleermuisvang-db/nuxt/pages/projects/[uuid]/visits/[visitId]/count.vue").then(m => m.default || m)
  },
  {
    name: editchYVrdv6oXMeta?.name ?? "projects-uuid-visits-visitId-edit",
    path: editchYVrdv6oXMeta?.path ?? "/projects/:uuid()/visits/:visitId()/edit",
    meta: editchYVrdv6oXMeta || {},
    alias: editchYVrdv6oXMeta?.alias || [],
    redirect: editchYVrdv6oXMeta?.redirect || undefined,
    component: () => import("/data/www/batobs.org/vleermuisvang-db/nuxt/pages/projects/[uuid]/visits/[visitId]/edit.vue").then(m => m.default || m)
  },
  {
    name: createhSeXJjv1G0Meta?.name ?? "projects-uuid-visits-create",
    path: createhSeXJjv1G0Meta?.path ?? "/projects/:uuid()/visits/create",
    meta: createhSeXJjv1G0Meta || {},
    alias: createhSeXJjv1G0Meta?.alias || [],
    redirect: createhSeXJjv1G0Meta?.redirect || undefined,
    component: () => import("/data/www/batobs.org/vleermuisvang-db/nuxt/pages/projects/[uuid]/visits/create.vue").then(m => m.default || m)
  },
  {
    name: create9ZTrX4lQlAMeta?.name ?? "projects-create",
    path: create9ZTrX4lQlAMeta?.path ?? "/projects/create",
    meta: create9ZTrX4lQlAMeta || {},
    alias: create9ZTrX4lQlAMeta?.alias || [],
    redirect: create9ZTrX4lQlAMeta?.redirect || undefined,
    component: () => import("/data/www/batobs.org/vleermuisvang-db/nuxt/pages/projects/create.vue").then(m => m.default || m)
  },
  {
    name: indexx1xkn1YEt8Meta?.name ?? "projects",
    path: indexx1xkn1YEt8Meta?.path ?? "/projects",
    meta: indexx1xkn1YEt8Meta || {},
    alias: indexx1xkn1YEt8Meta?.alias || [],
    redirect: indexx1xkn1YEt8Meta?.redirect || undefined,
    component: () => import("/data/www/batobs.org/vleermuisvang-db/nuxt/pages/projects/index.vue").then(m => m.default || m)
  },
  {
    name: create5edMsShuhEMeta?.name ?? "visits-create",
    path: create5edMsShuhEMeta?.path ?? "/visits/create",
    meta: create5edMsShuhEMeta || {},
    alias: create5edMsShuhEMeta?.alias || [],
    redirect: create5edMsShuhEMeta?.redirect || undefined,
    component: () => import("/data/www/batobs.org/vleermuisvang-db/nuxt/pages/visits/create.vue").then(m => m.default || m)
  },
  {
    name: indexGfUy1q6slYMeta?.name ?? "visits",
    path: indexGfUy1q6slYMeta?.path ?? "/visits",
    meta: indexGfUy1q6slYMeta || {},
    alias: indexGfUy1q6slYMeta?.alias || [],
    redirect: indexGfUy1q6slYMeta?.redirect || undefined,
    component: () => import("/data/www/batobs.org/vleermuisvang-db/nuxt/pages/visits/index.vue").then(m => m.default || m)
  }
]