import revive_payload_client_gLGMP7pqGl from "/data/www/batobs.org/vleermuisvang-db/nuxt/node_modules/.pnpm/nuxt@3.5.3_@types+node@20.3.0_eslint@8.42.0_stylelint@15.10.3_typescript@5.1.3/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import components_plugin_KR1HBZs4kY from "/data/www/batobs.org/vleermuisvang-db/nuxt/.nuxt/components.plugin.mjs";
import unhead_O3VWwdOEpJ from "/data/www/batobs.org/vleermuisvang-db/nuxt/node_modules/.pnpm/nuxt@3.5.3_@types+node@20.3.0_eslint@8.42.0_stylelint@15.10.3_typescript@5.1.3/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_ROkhU3aFCe from "/data/www/batobs.org/vleermuisvang-db/nuxt/node_modules/.pnpm/nuxt@3.5.3_@types+node@20.3.0_eslint@8.42.0_stylelint@15.10.3_typescript@5.1.3/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import prefetch_client_bYQtmumDUU from "/data/www/batobs.org/vleermuisvang-db/nuxt/node_modules/.pnpm/nuxt@3.5.3_@types+node@20.3.0_eslint@8.42.0_stylelint@15.10.3_typescript@5.1.3/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_KjLNUMOoPF from "/data/www/batobs.org/vleermuisvang-db/nuxt/node_modules/.pnpm/nuxt-bugsnag@6.5.2/node_modules/nuxt-bugsnag/dist/runtime/plugin.mjs";
import composition_Srai3JarID from "/data/www/batobs.org/vleermuisvang-db/nuxt/node_modules/.pnpm/@nuxtjs+i18n@8.0.0-rc.2_vue@3.3.4/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_TpCC8Lraa4 from "/data/www/batobs.org/vleermuisvang-db/nuxt/node_modules/.pnpm/@nuxtjs+i18n@8.0.0-rc.2_vue@3.3.4/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import formkitPlugin_pZqjah0RUG from "/data/www/batobs.org/vleermuisvang-db/nuxt/.nuxt/formkitPlugin.mjs";
import plugin_RLqgVOEnn4 from "/data/www/batobs.org/vleermuisvang-db/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-module-apollo@1.0.0-beta.3_react-dom@18.2.0_react@18.2.0_typescript@5.1.3_vue@3.3.4/node_modules/@bttr-devs/nuxt-module-apollo/dist/runtime/plugin.mjs";
import plugin_Lyg8enwq9G from "/data/www/batobs.org/vleermuisvang-db/nuxt/node_modules/.pnpm/@sidebase+nuxt-auth@0.5.0_next-auth@4.21.1/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.mjs";
import chunk_reload_client_3QtpqHqp82 from "/data/www/batobs.org/vleermuisvang-db/nuxt/node_modules/.pnpm/nuxt@3.5.3_@types+node@20.3.0_eslint@8.42.0_stylelint@15.10.3_typescript@5.1.3/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import apollo_1ILFkzpYSr from "/data/www/batobs.org/vleermuisvang-db/nuxt/plugins/apollo.ts";
import openreplay_client_x9gNryAGwo from "/data/www/batobs.org/vleermuisvang-db/nuxt/plugins/openreplay.client.ts";
import apollo_04PIzDQFlG from "/data/www/batobs.org/vleermuisvang-db/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie-auth@1.0.0-beta.14_@unhead+vue@1.1.27_next@13.4.5_nuxt@3.5.3_reac_wsuh63s34rbft4bw6dwskjwomy/node_modules/@bttr-devs/nuxt-layer-sofie-auth/plugins/apollo.ts";
import auth_0V6WcxwOYa from "/data/www/batobs.org/vleermuisvang-db/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie-auth@1.0.0-beta.14_@unhead+vue@1.1.27_next@13.4.5_nuxt@3.5.3_reac_wsuh63s34rbft4bw6dwskjwomy/node_modules/@bttr-devs/nuxt-layer-sofie-auth/plugins/auth.ts";
import apollo_2baVWoYasH from "/data/www/batobs.org/vleermuisvang-db/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie@1.0.0-beta.14_@unhead+vue@1.1.27_nuxt@3.5.3_react-dom@18.2.0_reac_k7f22vbjop3t4ieda43xusqfoy/node_modules/@bttr-devs/nuxt-layer-sofie/plugins/apollo.ts";
import errors_ozsuRLGGQC from "/data/www/batobs.org/vleermuisvang-db/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-base@1.0.0-beta.14_@unhead+vue@1.1.27_nuxt@3.5.3_tailwindcss@3.3.2_vue@3.3.4_webpack@5.86.0/node_modules/@bttr-devs/nuxt-layer-base/plugins/errors.ts";
import i18n_t9e2RM2viJ from "/data/www/batobs.org/vleermuisvang-db/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-base@1.0.0-beta.14_@unhead+vue@1.1.27_nuxt@3.5.3_tailwindcss@3.3.2_vue@3.3.4_webpack@5.86.0/node_modules/@bttr-devs/nuxt-layer-base/plugins/i18n.ts";
export default [
  revive_payload_client_gLGMP7pqGl,
  components_plugin_KR1HBZs4kY,
  unhead_O3VWwdOEpJ,
  router_ROkhU3aFCe,
  prefetch_client_bYQtmumDUU,
  plugin_KjLNUMOoPF,
  composition_Srai3JarID,
  i18n_TpCC8Lraa4,
  formkitPlugin_pZqjah0RUG,
  plugin_RLqgVOEnn4,
  plugin_Lyg8enwq9G,
  chunk_reload_client_3QtpqHqp82,
  apollo_1ILFkzpYSr,
  openreplay_client_x9gNryAGwo,
  apollo_04PIzDQFlG,
  auth_0V6WcxwOYa,
  apollo_2baVWoYasH,
  errors_ozsuRLGGQC,
  i18n_t9e2RM2viJ
]