<template>
  <div>
    <RadioGroup
      :model-value="selectedOption"
      class="flex justify-between"
      @update:model-value="updateModelValue"
    >
      <RadioGroupOption
        v-for="option in context.options"
        :key="option.label"
        v-slot="{checked}"
        :value="option"
      >
        <div
          :class="[checked ? 'border-indigo-700 bg-indigo-500 text-white' : 'bg-white', context.options.length > 5 ? 'px-4' : 'px-5']"
          class="cursor-pointer rounded-lg border-2 py-4 leading-none"
        >
          <RadioGroupLabel
            as="span"
            class="font-semibold"
          >
            {{ option.label }}
          </RadioGroupLabel>
        </div>
      </RadioGroupOption>
      <button
        type="button"
        class="transition-opacity duration-150"
        :class="{'opacity-30': !selectedOption}"
        @click="updateModelValue({value: null})"
      >
        <UiIcon
          name="heroicons:x-mark"
          size="medium"
        />
      </button>
    </RadioGroup>
  </div>
</template>

<script lang="ts" setup>
import {FormKitFrameworkContext} from '@formkit/core';
import {RadioGroup, RadioGroupLabel, RadioGroupOption} from '@headlessui/vue';

const props = defineProps<{
  context: FormKitFrameworkContext
}>();

const selectedOption = computed(() => {
  if (!props.context.value || !props.context.options) {
    return null;
  }

  return props.context.options.find((o: object) => o.value === props.context.value);
});

function updateModelValue({value}) {
  props.context.node.input(value);
}
</script>
