<template>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>

<script setup lang="ts">
useHead({
  titleTemplate: (titleChunk) => {
    return titleChunk ? `${titleChunk} - BatObs.org` : 'BatObs.org';
  },
});

onMounted(() => {
  const {$startTracking} = useNuxtApp();
  $startTracking();
});
</script>
