import { defineNuxtPlugin } from '#app/nuxt'
import { LazyFog, LazyRain, LazySave, LazyThermometer, LazyWind, LazyWindDirection, LazyIcon, LazyIconCSS } from '#components'
const lazyGlobalComponents = [
  ["Fog", LazyFog],
["Rain", LazyRain],
["Save", LazySave],
["Thermometer", LazyThermometer],
["Wind", LazyWind],
["WindDirection", LazyWindDirection],
["Icon", LazyIcon],
["IconCSS", LazyIconCSS]
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
