import {provideApolloClient} from '@vue/apollo-composable';

export default defineNuxtPlugin((nuxtApp) => {
  provideApolloClient(useApollo().clients.default);

  const {getSession} = useAuth();

  nuxtApp.hook('apollo:error', (error) => {
    for (const graphqlError of error.graphQLErrors ?? []) {
      if (graphqlError.extensions?.category === 'authorization' && graphqlError.message === 'Unauthorized') {
        // This checks the session, and causes a redirect to login on error
        getSession();

        break;
      }
    }
  });
});
