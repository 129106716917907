<template>
  <div
    class="mb-8 w-full text-center"
    :data-type="context.type"
  >
    <div
      class="text-secondary-dark relative h-36 rounded-xl border-2 border-gray-300 p-5"
      :class="context.value ? 'border' : 'border-dashed'"
    >
      <template v-if="!context.value">
        <div class="flex w-full cursor-pointer flex-col items-center justify-center p-5 text-center">
          <UiIcon
            name="heroicons:photo"
            size="large"
          />
          <p class="mb-2.5 text-sm">
            <span class="text-indigo-700">Upload a file</span> or drag and drop
          </p>
          <p class="text-xs text-gray-500">
            {{ attributes.accept.toUpperCase() }} up to {{ maxFileSize }} MB
          </p>
        </div>
        <input
          :id="context.node.name"
          class="absolute top-0 block h-full w-full cursor-pointer opacity-0"
          type="file"
          :multiple="wantsMultiple"
          :accept="attributes.accept || '*/*'"
          @change="uploadFile"
        >
      </template>
      <template v-else>
        <button
          class="absolute right-2 top-2 flex h-7 w-7 items-center justify-center rounded-full bg-white"
          type="button"
          @click="confirmRemoveImage"
        >
          <UiIcon name="heroicons:x-circle" />
        </button>
        <img
          v-if="!wantsMultiple && ['jpg', 'jpeg', 'png', 'gif'].includes(extension)"
          :src="uploadedImage"
          :alt="context.value.name"
          class="h-full w-full rounded-xl object-cover"
        >
        <div
          v-else
          class="mt-4"
        >
          <UiIcon
            name="heroicons:document"
            size="large"
          />
        </div>
        <p
          class="py-3"
          v-text="fileName"
        />
      </template>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {FormKitFrameworkContext} from '@formkit/core';

const props = defineProps<{
  context: FormKitFrameworkContext,
}>();

const attributes = computed(() => Object.assign(props.context?.attrs, {id: null, class: null}));
const maxFileSize = computed(() => attributes.value.maxFileSize || 15); // In MB's
const wantsMultiple = computed(() => attributes.value?.multiple);

const uploadedImage = computed(() => {
  const imageValue = props.context.value;
  if (!imageValue) {
    return null;
  }

  return imageValue instanceof File ? URL.createObjectURL(imageValue) : imageValue.url;
});

const fileName = computed(() => {
  if (wantsMultiple.value) {
    return props.context.value.name;
  }

  return Array.from(props.context.value).reduce((acc: string, curr: File) => {
    if (!acc.length) {
      return curr.name;
    }

    return `${acc}, ${curr.name}`;
  }, '');
});

const extension = computed(() => {
  const value = props.context?.value;

  return value.name.substr(value.name.lastIndexOf('.') + 1, value.name.length);
});

function uploadFile(event) {
  const input = event.target;

  if (!input.files) {
    return;
  }

  if (wantsMultiple.value) {
    props.context.node.input(input.files);
    return;
  }

  Array.from(input.files).forEach((file) => {
    if (!file) {
      return;
    }

    const sizeInMB = file.size / 1024 / 1024;

    if (sizeInMB > maxFileSize.value) {
      console.error('File exceeded max file limit');

      return;
    }
    const reader = new FileReader();

    reader.onload = () => {
      props.context.node.input(file);
    };

    reader.readAsDataURL(file); // convert to base64 string
  });
}

function confirmRemoveImage() {
  if (!confirm('Are you sure you want to remove this image from the capture?')) {
    return;
  }

  props.context.node.input(null);
}
</script>
