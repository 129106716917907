import {startTracker} from '~/utils/openreplay';

export default defineNuxtPlugin(() => ({
  provide: {
    startTracking: () => {
      const isProduction = process.env.NODE_ENV === 'production';
      const config = useRuntimeConfig().public;
      const user = useSofieUserWhenAuthenticated();

      if (!isProduction) {
        return;
      }

      const {tracker, userId} = startTracker({
        userIdEnabled: true,
        getUserId: () => {
          return user.value?.email;
        },
        projectKey: config.openreplayProjectKey,
        isProduction,
      });

      watch(user, () => tracker.setUserID(user.value?.email ?? userId));
    },
  },
}));
